import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Explore.css';

export default function Landing() {
    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}>
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Legal Documents</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section className={`section section1 section--products mx-0 my-0 pt-5 bg-white ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fs-2 text-blue">
                            <strong><span>Legal Documents</span></strong>
                        </h1>
                        <hr />
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-6">
                            <article className="article article--news height-auto">
                                <Link to={`/middlebyCelfrostFormMGT72022to23`}>
                                    <div className="inner height-auto">
                                        <div className="bottom bg-red">
                                            <div className="content h-220 align-content-center">
                                                <h4 className="fw-bold">Middleby Celfrost Form_MGT_7 - 2022-23<br/><br/></h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <article className="article article--news height-auto">
                            <Link to={`/formMGT7MiddlebyCelfrost2021to22`}>
                                    <div className="inner height-auto">
                                        <div className="bottom bg-red">
                                            <div className="content h-220 align-content-center">
                                                <h4 className="fw-bold">Form_MGT_7 - Middleby Celfrost - 2021-22<br/><br/></h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <article className="article article--news height-auto">
                                <Link to={`/formMGT7Celfrost2020to21`}>
                                    <div className="inner height-auto">
                                        <div className="bottom bg-red">
                                            <div className="content h-220 align-content-center">
                                                <h4 className="fw-bold">Form_MGT_7 - Celfrost 2020-21<br/><br/></h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <article className="article article--news height-auto">
                                <Link to={`/cSRPolicyMiddlebyCLFV2`}>
                                    <div className="inner height-auto">
                                        <div className="bottom bg-red">
                                            <div className="content h-220 align-content-center">
                                                <h4 className="fw-bold">CSR Policy_Middleby CLF - V2<br/><br/></h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </article>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}