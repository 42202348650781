import React from 'react';
import { Link } from 'react-router-dom';
import '../Explore.css'

export default function Landing() {
    const baseUrl = `${process.env.REACT_APP_API_URL}uploads/MGT72022-2023/`;
    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}>
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                                           <Link to={`/legal-documents`}>
                                                                <span itemProp="name">Legal Documents</span>
                                                            </Link>
                                                            <meta itemProp="position" content="1" />
                                                        </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Middleby Celfrost Form MGT7 2022 23</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="section section--contact">
                <div className="container">
                    <div className="row g-4 mb-5">
                        <div className="col-lg-10">
      <img src={`${baseUrl}MGT72022-2023_page-0001.jpg`} alt="MGT72022-2023_page-0001" title="MGT72022-2023_page-0001" />
      <img src={`${baseUrl}MGT72022-2023_page-0002.jpg`} alt="MGT72022-2023_page-0002" title="MGT72022-2023_page-0002" />
      <img src={`${baseUrl}MGT72022-2023_page-0003.jpg`} alt="MGT72022-2023_page-0003" title="MGT72022-2023_page-0003" />
      <img src={`${baseUrl}MGT72022-2023_page-0004.jpg`} alt="MGT72022-2023_page-0004" title="MGT72022-2023_page-0004" />
      <img src={`${baseUrl}MGT72022-2023_page-0005.jpg`} alt="MGT72022-2023_page-0005" title="MGT72022-2023_page-0005" />
      <img src={`${baseUrl}MGT72022-2023_page-0006.jpg`} alt="MGT72022-2023_page-0006" title="MGT72022-2023_page-0006" />
      <img src={`${baseUrl}MGT72022-2023_page-0007.jpg`} alt="MGT72022-2023_page-0007" title="MGT72022-2023_page-0007" />
      <img src={`${baseUrl}MGT72022-2023_page-0008.jpg`} alt="MGT72022-2023_page-0008" title="MGT72022-2023_page-0008" />
      <img src={`${baseUrl}MGT72022-2023_page-0009.jpg`} alt="MGT72022-2023_page-0009" title="MGT72022-2023_page-0009" />
      <img src={`${baseUrl}MGT72022-2023_page-0010.jpg`} alt="MGT72022-2023_page-0010" title="MGT72022-2023_page-0010" />
      <img src={`${baseUrl}MGT72022-2023_page-0011.jpg`} alt="MGT72022-2023_page-0011" title="MGT72022-2023_page-0011" />
      <img src={`${baseUrl}MGT72022-2023_page-0012.jpg`} alt="MGT72022-2023_page-0012" title="MGT72022-2023_page-0012" />
      <img src={`${baseUrl}MGT72022-2023_page-0013.jpg`} alt="MGT72022-2023_page-0013" title="MGT72022-2023_page-0013" />
      <img src={`${baseUrl}MGT72022-2023_page-0014.jpg`} alt="MGT72022-2023_page-0014" title="MGT72022-2023_page-0014" />
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}