import React from 'react';

import { Link } from 'react-router-dom';
import '../Explore.css'

export default function Landing() {
    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}>
                                    <p itemProp="name">Home</p>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                               <Link to={`/legal-documents`}>
                                    <span itemProp="name">Legal Documents</span>
                                </Link>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <p itemProp="item">
                                    <p itemProp="name">
                                        <strong>CSR Policy Middleby CLFV2</strong>
                                    </p>
                                </p>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="section section--contact">
                <div className="container">
                    <div className="row g-4 mb-5">
                        <div className="col-lg-12">
                            <div className="page-container">
                                <div className="page">
                                    <div className="text-container">
                                        <h3 className="primarycolor h1 fw-bold">Middleby Celfrost Innovations Private Limited </h3>
                                        <h3 className="h1 fw-bold mb-4"> Corporate Social Responsibility Policy </h3>
                                    </div>
                                </div>
                            </div>


                            <div className="page-container">
                                <div className="page">
                                    <div>
                                        <p>This Corporate Social Responsibility Policy (“the CSR Policy”) has been framed by Middleby Celfrost Innovations Pvt. Ltd (the Company) in accordance with the Section 135, Schedule VII of the Companies Act, 2013 and CSR Rules issued by the Ministry of Corporate Affairs on February 27, 2014 as updated from time to time.</p>
                                        <p>Unless the context otherwise requires, the definitions mentioned in the notification dated February 27, 2014 and Companies Act 2013, shall apply to this CSR Policy. </p>

                                        <h5 className='s1 mt-5 mb-3'>Company Philosophy for CSR Policy: </h5>
                                        <p className='mb-2'>Companies Act, 2013 has formally introduced the Corporate Social Responsibility to the dash board of the Indian Companies. Middleby Celfrost Innovations Pvt .Ltd is much positive and appreciate the decision taken by the Government of India. Legal framework of CSR is an edge to Corporate Charitable/reformative approach towards the Society to which the Corporate is belonging to. By introducing the separate section for CSR in Companies Act, 2013, the Government has given legal recognition to their community development approach.</p>
                                        <p>The management of the Middleby Celfrost Innovations Pvt. Ltd expresses its willingness and support to the CSR concept, its legal framework and shall be abided to it.</p>
                                        <h5 className='s1 mt-5 mb-3'>Corporate Social Responsibility Committee: </h5>
                                        <p className='mb-2'>The Company has constituted Corporate Social Responsibility Committee (“the Committee”) <br /> comprising of all the three Directors of the company: </p>
                                        <p className="t v3">1. Mr. Michael Dennis Thompson - Chairman </p>
                                        <p className="t v3">2. Bryan Eric Mittelman - Member </p>
                                        <p className="t v3 mb-3">3. Mr. Balaji Subramaniam - Member </p>
                                        <p>Board of Directors of the Company may re-constitute the Committee, as and when
                                            required to do so, by following the sections, sub-sections, rules, regulations,
                                            notifications issued or to be issued, from time to time, by the Ministry of Corporate
                                            Affairs or the Central Government of India. The Committee shall exercise powers and
                                            perform the functions assigned to it by the Board of Directors of the Company pursuant
                                            to section 135 of the Companies Act, 2013 and CSR Rules notified with regard thereto.</p>
                                    </div>
                                </div>
                            </div>



                            <div className="page-container">
                                <div className="page">
                                    <div className="text-container">
                                        <h5 className='s1 mt-5 mb-3'>CSR Activities: </h5>
                                        <p>Pursuant to Schedule VII of the Companies Act, 2013, the Committee has approved the
                                            following activities as “CSR Activities” to be undertake under the CSR policy of the
                                            Company. The Board of Directors has reviewed the said activities and express its
                                            consent to the Committee to pursue the said activities under CSR policy of the
                                            Company under section 135 of the Companies Act, 2014, Schedule VII and other
                                            applicable rules, regulations, notifications etc., issued/to be issued from time to time.</p>
                                        <h6 className='s2 mt-4 mb-4'>Approved CSR Activities: </h6>
                                        <p className='mb-3'>1. Promoting preventive healthcare and sanitation to the public; </p>
                                        <p className='mb-3'>2. Promoting education, including primary, secondary and higher secondary education, as well as special education and employment enhancing vocation skills especially among children, women, elderly and the differently abled and livelihood enhancement projects; </p>
                                        <p className='mb-3'>3. Projects, Activities, Programmes relating to conservation of natural resources including renewable energy sources; </p>
                                        <p className='mb-3'>4. Projects, Activities, Programmes relating to water conservation and maintaining quality of soil, air, and water; </p>
                                        <p className='mb-3'>5. Promoting Gender equality and empowerment of women </p>
                                        <p className='mb-3'>6. Community Health- Innovation projects to meet local needs. Reaching out with basic health care to all (bridging the gap). </p>
                                        <p className='mb-3'>7. Sustainable livelihood Projects - Holding hands of all marginalized group to improve livelihood opportunity, thus improving their quality of life. </p>
                                        <p className='mb-3'>8. Rural Infrastructure Development- Need based quality infrastructure to improve quality of life. </p>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div>
                                    <div>
                                        <h5 className='s1 mt-5 mb-3'>Budget for CSR Activity & CSR Expenses: </h5>
                                        <p>The Company shall allocate the budget for CSR activities. The minimum budgeted
                                            amount for a financial year shall be 2% of the average net profit of three immediate
                                            preceding financial years. The Company may allocate more fund/amount than the
                                            amount prescribed under section 135 oftheCompaniesAct, 2013, for theCSRactivities
                                            for any financial year. TheCommittee shall calculate the totalfund for theCSRactivities
                                            and recommend to the Board for the approval. The Board shall approve the total fund
                                            to be utilized for CSR activity for respective financial year.</p>
                                        <p>CSR expenditure shall include all expenditure including contribution to corpus for
                                            projects or programs relating to CSR activities approved by the Board on the
                                            recommendation of its CSR Committee, but does not include any expenditure on an
                                            item not in conformity or not in line with activities which fall within the approved CSR activities</p>
                                        <h5 className='s1 mt-5 mb-3'>Identification of activities/projects: </h5>
                                        <p>Out of approved CSR activities, the Committee shall decide which activity/project
                                            should be given priority for the respective financial year. While arriving at the decision of the activity to be undertaken for the respective year, the Committee shall analyze the basic need of the community/ area in which the Organization operates or at the place where its registered office is situated. The Committee shall record its findings and prioritized the CSR activities</p>
                                        <h5 className='s1 mt-5 mb-3'>Implementation process: </h5>
                                        <p>After prioritizing the activity, the Committee shall finalize the detail implementation
                                            project/programme, including planning for expenses against the total budget allocated
                                            for CSR activities.</p>
                                        <h5 className='s1 mt-5 mb-3'>Organizational Responsibility: </h5>
                                        <p>At organizational level for implementation of agreed CSR activity, the committee may
                                            constitute an implementation team or authorized any of the Department of the Company to organize for the implementation of the CSR activity. The team or respective department shall monitor the implementation process from time to time, on</p>
                                        <p className='mt-3 mb-3'>behalf of the CSR committee and place a report to the Committee regarding the
                                            progress of the activity implementation, on a quarterly basis. The respective department or implementation team would be responsible for reporting of any irregularity to the Committee on immediate basis.</p>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div>
                                    <div>
                                        <h5 className='s1 mt-5 mb-3'>Monitoring by the Board: </h5>
                                        <p>The Board of Directors shall constantly monitor the implementation of the CSR
                                            activities. The CSR committee shall place a progress report, including details of
                                            expenses, before the Board on quarterly basis. The Board shall review the same and
                                            suggest recommendation, if any, to the committee with regard to implementation
                                            process.</p>
                                        <h5 className='s1 mt-5 mb-3'>Compliance and Reporting to Board: </h5>
                                        <p>The Committee is responsible to undertake CSR activities as per the approved CSR
                                            Policy. Apart from quarterly reporting to the Board about the implementation of CSR
                                            activity, a detail report containing the implementation schedule, total budget allocated, actual expenses incur, surplus arising, if any, result achieved, further work to do in the concern CSR activity, Recommendation for the CSR activities for next year etc. should be placed before the Board for its consideration. Any surplus arising out of the fund allocated for CSR activity shall not be the part of the business  profit of the Company.</p>
                                        <h5 className='s1 mt-5 mb-3'>Management Commitment: </h5>
                                        <p>Our Board of Directors, our Management and all of our employees subscribe to
                                            the philosophy of compassionate care. We believe and act on an ethos of
                                            generosity and compassion, characterized by a willingness to build a society
                                            that works for everyone. This is the cornerstone of our CSR policy.</p>
                                        <p className='mt-3'>Our Corporate Social Responsibility policy conforms to the relevant section of the Corporate Social Responsibility, Rules made under Companies Act, 2013 and amendment(s) to be made thereto in future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}